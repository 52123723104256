import { render, staticRenderFns } from "./PropositionDetails.vue?vue&type=template&id=44b23461&scoped=true&"
import script from "./PropositionDetails.vue?vue&type=script&lang=js&"
export * from "./PropositionDetails.vue?vue&type=script&lang=js&"
import style0 from "./PropositionDetails.vue?vue&type=style&index=0&id=44b23461&lang=scss&scoped=true&"
import style1 from "./PropositionDetails.vue?vue&type=style&index=1&id=44b23461&lang=scss&scoped=true&"
import style2 from "./PropositionDetails.vue?vue&type=style&index=2&lang=scss&"
import style3 from "./PropositionDetails.vue?vue&type=style&index=3&lang=scss&"
import style4 from "./PropositionDetails.vue?vue&type=style&index=4&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44b23461",
  null
  
)

export default component.exports