<template>
  <div
    v-if="
      getUser.role.slug === 'responsable-relation-client' ||
        getUser.role.slug === 'super-administrateur' ||
        getUser.role.slug === 'charge-daffaires'
    "
  >
   <b-breadcrumb class="mb-2 ">
            <b-breadcrumb-item to="/orders/recurring" >Packages</b-breadcrumb-item>
            <b-breadcrumb-item>
             <router-link :to="{ name: 'commande', params: { id: this.$route.query.packageId } }">Commandes</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>Détails</b-breadcrumb-item>
        </b-breadcrumb>
    <div
      v-if="isPageLoading"
      class="d-flex justify-content-center mb-1"
      style="padding-top: 30vh"
    >
      <b-spinner
        variant="info"
        style="width: 3rem; height: 3rem"
        class="mr-1"
      />
    </div>
    <div
      v-else-if="isError"
      class="d-flex flex-column justify-content-center mt-5 text-center"
      style="padding-top: 30vh"
    >
      <h1 class="text-danger">
        <feather-icon
          icon="XCircleIcon"
          size="100"
        />
      </h1>
      <p class="font-medium-1 text-danger font-weight-normal">
        Aucune commande ne correspond à cet identifiant
      </p>
    </div>
    <div v-else>
      <template>
        <div>
          <b-row class="invoice-preview">
            <!-- Col: Left (Invoice Container) -->
            <b-col
              cols="12"
              xl="9"
              md="8"
            >
              <b-card
                no-body
                class="invoice-preview-card"
              >
                <!-- Header -->
                <b-card-body class="invoice-padding pb-0">
                  <div
                    class="
                      d-flex
                      justify-content-between
                      flex-md-row flex-column
                      invoice-spacing
                      mt-0
                    "
                  >
                    <!-- Header: Left Content -->
                    <div>
                      <div class="logo-wrapper">
                        <b-avatar
                          :text="
                            avatarText(
                              `${proposition.user.first_name} ` +
                              ` ${proposition.user.last_name}`
                            )
                          "
                          :src="proposition.user.profile_image"
                          size="104px"
                          rounded
                        />
                      </div>
                      <h3 class="mt-2 text-success mb-25">
                        {{ proposition.user.full_name }}
                      </h3>
                    </div>

                    <!-- Header: Right Content -->
                    <div class="mt-md-0 mt-2">
                      <h4 class="invoice-title">
                        Commande
                        <span class="invoice-number text-success">
                          #{{ directOrder.id }}
                        </span>
                        <b-badge
                          pill
                          :variant="`light-${resolveOrderStatusVariant(
                            directOrder.status,
                            directOrder.placement_cost_paid,
                            directOrder.search_employee
                          )}`"
                          class="text-capitalize font-medium-3"
                        >
                          {{ directOrder.setStatus }}
                        </b-badge>
                      </h4>
                      <div class="invoice-date-wrapper ">
                        <p class="invoice-date-title">
                          Téléphone:
                        </p>
                        <small class="font-weight-bold text-info font-small-4">
                          {{ proposition.user.phone_number }}
                        </small>
                          
                      </div>
                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title">
                          Email:
                        </p>
                        <small class="font-weight-bold text-info font-small-4">
                          {{ proposition.user.email }}
                        </small>
                      </div>
                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title">
                          Quartier:
                        </p>
                        <small class="font-weight-bold text-info font-small-4">
                          {{ directOrder.address }}
                        </small>
                      </div>
                    </div>
                  </div>
                </b-card-body>

                <!-- Spacer -->
                <hr class="invoice-spacing">

                <!-- Invoice Client & Payment Details -->
                <b-card-body class="pt-0">
                  <h6 class="mb-2 text-center font-medium-2">
                    Détails de la commande
                  </h6>
                  <b-row class="">
                    <!-- Col: Invoice To -->
                    <b-col
                      md="6"
                      cols="12"
                    >
                      <p class="card-text mb-25 font-medium-1">
                        Type de demande:
                        <span class="text-info">
                          {{
                            directOrder.search_employee
                              ? "Recherche d'employé"
                              : "Gestion d'employé existant"
                          }}
                        </span>
                      </p>
                      <p class="card-text mb-25 font-medium-1">
                        Salaire net:
                        <span class="text-info">
                          {{ directOrder.employee_salary }} FCFA
                        </span>
                      </p>
                      <p class="card-text mb-25 font-medium-1">
                        Service demandé:
                        <span class="text-info">{{
                          directOrder.recurring_service.name
                        }}</span>
                      </p>
                      <p class="card-text mb-25 font-medium-1">
                        Date de la demande:
                        <span class="text-info">
                          {{
                            moment(directOrder.created_at)
                              .locale("fr")
                              .format("llll")
                          }}
                        </span>
                      </p>
                    </b-col>
                    <b-col
                      md="6"
                      cols="12"
                    >
                      <!-- <p class="card-text mb-25 font-medium-1">
                        Type d'offre:
                        <span class="text-info">
                          {{ directOrder.offer_type.name }}
                        </span>
                      </p> -->
                      <p class="card-text mb-25 font-medium-1">
                        Fréquence d'intervention:
                        <span class="text-info">
                          {{ directOrder.intervention_frequency }}
                        </span>
                      </p>
                      <p class="card-text mb-0 font-medium-1">
                        Frais de placement:
                        <span class="text-info">
                          {{
                            directOrder.placement_cost_paid
                              ? "Payés"
                              : "Non payés"
                          }}
                        </span>
                      </p>
                      <p class="card-text mb-0 font-medium-1">
                        Nombre d'employés recherché:
                        <span class="text-info">
                          {{
                            directOrder.number_of_employees
                              
                          }}
                        </span>
                      </p>
                      <p class="card-text mb-0 font-medium-1">
                        CNSS:
                        <span class="text-info">
                          {{
                            directOrder.cnss == true ? 'Oui' : 'Non'
                              
                          }}
                        </span>
                      </p>
                    </b-col>
                    <!-- Col: Payment Details -->
                  </b-row>
                </b-card-body>

              </b-card>
            </b-col>
          </b-row>      
        </div>
       
        <!-- Sending Feedback Modal -->
        <b-modal
          id="modal-send-feedback"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          no-close-on-backdrop
          no-close-on-esc
          title="Feedback client"
        >
          <validation-observer
            #default="{}"
            ref="orderAssignmentForm"
          >
            <!-- Form -->
            <b-form
              class="p-2"
              autocomplete="off"
              @reset.prevent="hideModalSendFeedback()"
              @submit.prevent="applySendFeedbackAction()"
            >
              <b-form-group
                label="Résumé de votre discussion avec le  client"
                label-for="textarea-default"
              >
                <validation-provider
                  #default="{ errors }"
                  name="feedback"
                  rules="required"
                >
                  <b-form-textarea
                    id="textarea-default"
                    v-model="payload.feedback"
                    :state="errors.length > 0 ? false : null"
                    placeholder=""
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  :disabled="isFeedbackSending"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                >
                  <div v-if="isFeedbackSending">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>
                  <span v-else>Soumettre</span>
                </b-button>
                <b-button
                  type="reset"
                  variant="outline-secondary"
                  @click="hideModalSendFeedback()"
                >
                  Annuler
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-modal>

        <!-- List Proposition Modal -->
        <b-modal
          id="modal-proposed-employee"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          no-close-on-backdrop
          no-close-on-esc
          title="Propositions effectuées"
          size="xl" 
        >
          <b-table
            ref="refInvoiceListTable"
            :items="propositions"
            responsive
            :fields="tableColumns1"
            primary-key="id"
            :busy="isProposedEmployeesLoading"
            show-empty
            empty-text="Liste vide"
            class="position-relative"
          >
            <template #table-busy>
              <div class="text-center text-info my-2">
                <b-spinner class="align-middle" /> &nbsp;
                <strong>Chargement...</strong>
              </div>
            </template>
            <!-- Column: Invoice Status -->
            <template #cell(is_rejected)="data">
              <b-badge
                pill
                :variant="`light-${resolvePropositionStatusVariant(data.item.status)}`"
                class="text-capitalize font-small-3"
              >
                {{
                   data.item.setStatus
                }}
              </b-badge>
            </template>
            <!-- Salaire -->
            <template #cell(salary)="data">
              <span>
                {{ data.item.recommandation.employee_salary }} FCFA
              </span>
            </template>
             <!-- Salaire -->
            <template #cell(comment)="data">
              <span>
                {{ data.item.recommandation.comment }}
              </span>
            </template>
            
            <!-- Column: employee -->
            <template #cell(employee)="data">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    :src="data.item.recommandation.employee.profile_image"
                  />
                </template>
                <span class="font-weight-bold d-block text-nowrap">
                  {{ data.item.recommandation.employee.full_name }}
                </span>
                <small class="text-success font-small-3">{{
  data.item.recommandation.employee.phone_number
                }}</small>
              </b-media>
            </template>
             <!-- Column: Employee contract started date -->
            <template #cell(employee_contract_started_date)="data" >
              <span  v-if="data.item.status === 2">
                {{
  moment(data.item.recommandation.employee_contract_started_date)
                    .locale("fr")
                    .format("dddd DD MMMM YYYY")
                }}
              </span>
              <span v-else-if="data.item.status === -2">
                {{
                 moment(data.item.recommandation.employee_contract_started_date)
                    .locale("fr")
                    .format("dddd DD MMMM YYYY")
                }}
              </span>
               <span v-else>
                Proposition pas encore acceptée
              </span>
            </template>

            <template #cell(reason)="data">
              <span class="text-info">
                {{
                  data.item.reason != null ? data.item.reason : "--------"
                }}
              </span>
            </template>

            <!-- Column: Employee contract ended date -->
            <template #cell(employee_contract_end_date)="data" >
              <span v-if="data.item.status === -2">
                {{
  moment(data.item.recommandation.employee_contract_end_date)
                    .locale("fr")
                    .format("dddd DD MMMM YYYY")
                }}
              </span>
              <span v-else-if="data.item.status === 2">
                Actif
              </span>
              <span v-else>
                En attente d'acceptation
              </span>
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <div class="text-nowrap">
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="25"
                      class="align-middle text-center text-info"
                    />
                  </template>
                  <b-dropdown-item
                   
                    :id="`open${data.item.id}`"
                    class="cursor-pointer itemActions"
                    @click="
                      openEmployeeContract(
                        directOrder
                      )
                    "
                  >
                    <feather-icon
                      icon="FileTextIcon"
                      class="text-success"
                      size="20"
                    />
                    <span class="align-middle ml-50">Contrat employé</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    
                    :id="`deploy${data.item.id}`"
                    class="cursor-pointer itemActions"
                    @click="deployEmployee(data.item) , clickedDeploy"
                  >
                    <feather-icon
                      icon="NavigationIcon"
                      class="text-success"
                      size="20"
                    />
                    <span class="align-middle ml-50">Déployer</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    
                    class="cursor-pointer itemActions"
                    @click="ApplyGetPropositionDetailsAction(data.item)"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      class="text-info"
                      size="20"
                    />
                    <span class="align-middle ml-50">Détails de la proposition</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="directOrder.status === 1 "
                    :disabled="data.item.status === 1 || data.item.status === 2"
                    :id="`delete${data.item.id}`"
                    class="cursor-pointer itemActions"
                    @click="
                      applyDeleteDirectOrderPropositionAction(
                        data.item.id)
                    "
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="text-danger"
                      size="20"
                    />
                    <span class="align-middle ml-50">Supprimer cette proposition</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    class="cursor-pointer itemActions"
                    @click="terminateContrat(data.item), clikedResilier"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="text-danger"
                      size="20"
                    />
                    <span class="align-middle ml-50">Résilier le contrat</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
                <!-- Terminate Employee Contract Modal Résilier-->
        <b-modal
          id="modal-terminate-employee-contrat"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          no-close-on-backdrop
          no-close-on-esc
         :title="`Résiliation du contrat de Employé ${data.item.recommandation.employee.full_name}` "
        >
          <validation-observer
            #default="{}"
            ref="terminateEmployeeContratForm"
          >
            <!-- Form -->
            <b-form
              class="p-2"
              autocomplete="off"
              @reset.prevent="hideModalTerminateEmployeeContrat()"
              @submit.prevent="applyTerminateEmployeeContractAction()"
              
            >
              <b-form-group
                label="Date d'arrêt d'activités de l'employé"
                label-for="role"
              >
                <validation-provider
                  #default="{ errors }"
                  name="date de résiliation"
                  rules="required"
                >
                  <flat-pickr
                    v-model="terminateEmployeePayload.date"
                    class="form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <validation-provider
                #default="{ errors }"
                name="resiliation-reason"
                rules="required"
              >
                <b-form-group
                  label="Raison de la résiliation"
                  label-for="reason"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-textarea
                    id="resiliation-reason"
                    v-model="terminateEmployeePayload.reason"
                    placeholder="Laissez la raison de la résiliation"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  :disabled="isTerminateEmployeeContratLoading"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                >
                  <div v-if="isTerminateEmployeeContratLoading">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>
                  <span v-else>Résilier</span>
                </b-button>
                <b-button
                  type="reset"
                  variant="outline-secondary"
                  @click="hideModalTerminateEmployeeContrat()"
                >
                  Annuler
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-modal>
            </template>
          </b-table>
        </b-modal>
       


        <!-- New Proposition Modal -->
        <b-modal
          id="modal-new-proposition"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          no-close-on-backdrop
          no-close-on-esc
          :title="`Proposition d'employé`"
        >
          <validation-observer
            #default="{}"
            ref="newPropositionForm"
          >
            <!-- Form -->
            <b-form
              class="p-2"
              autocomplete="off"
              @reset.prevent="hideModal"
              @submit.prevent="applyMakePropositionAction"
            >
              <b-form-group
                label="Choisissez un employé"
                label-for="role"
              >
                <validation-provider
                  #default="{ errors }"
                  name="pro"
                  rules="required"
                >
                  <b-form-select
                    v-if="
                      employeeOptions.length === 1 &&
                        employeeOptions[0].disabled
                    "
                    id="employee_id"
                    v-model="payload.employee_id"
                    :options="employeeOptions"
                    class="custom-select"
                  />

                  <v-select
                    v-else
                    id="role"
                    multiple
                    v-model="selectEmployee"
                    :reduce="(employee) => employee"
                    label="full_name"
                    :options="employeeOptions"
                    
                  >
                    <span
                      slot="no-options"
                      @click="$refs.select.open = false"
                    >
                      Chargement...
                    </span>
                    <template #option="{ comment, employee, employee_salary }">
                      <b-row>
                        <b-col md="3">
                          <b-avatar
                            size="80px"
                            rounded
                            :src="employee.profile_image"
                          />
                        </b-col>
                        <b-col md="9">
                          <b-row>
                            <b-col>
                              <span class="text-white">
                                {{ employee.full_name }}
                              </span>
                            </b-col>
                            <b-col>
                              <span class="text-white">
                                {{ employee.age }} ans
                              </span>
                            </b-col>
                          </b-row>
                          <b-row class="">
                            <b-col>
                              <span class="text-white">
                                {{ employee.marital_status }}
                              </span>
                            </b-col>
                            <b-col>
                              <span class="text-white">{{
                                employee.address
                              }}</span>
                            </b-col>
                          </b-row>

                          <b-row class="">
                            <b-col>
                              <span class="text-white">
                                Commentaire: {{ comment }}
                              </span>
                            </b-col>
                            <b-col>
                              <span class="text-white">
                                Salaire net: {{ employee_salary }}
                              </span>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </template>
                  </v-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

             

              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  :disabled="isMakingPropositionLoading"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                >
                  <div v-if="isMakingPropositionLoading">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>
                  <span v-else>Envoyer</span>
                </b-button>
                <b-button
                  type="reset"
                  variant="outline-secondary"
                  @click="hideModal()"
                >
                  Annuler
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-modal>

       

        <!-- Modify Proposition Modal -->
        <b-modal
          id="modal-modify-proposition"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          no-close-on-backdrop
          no-close-on-esc
          :title="`Modifier une proposition`"
        >
          <validation-observer
            #default="{}"
            ref="modifyPropositionForm"
          >
            <!-- Form -->
            <b-form
              class="p-2"
              autocomplete="off"
              @reset.prevent="hidePropositionModificationModal"
              @submit.prevent="applyModifyPropositionAction"
            >
              <b-form-group
                label="Choisissez un employé"
                label-for="role"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Employé"
                  rules="required"
                >
                  <b-form-select
                    v-if="
                      employeeOptions.length === 1 &&
                        employeeOptions[0].disabled
                    "
                    id="employee_id"
                    multiple
                    v-model="selectEmployee"
                    :options="employeeOptions"
                  />
                  <v-select
                    v-else
                    id="role"
                    multiple
                    v-model="selectEmployee"
                    :reduce="(employee) => employee"
                    label="full_name"
                    :options="employeeOptions"
                  >
                    <span
                      slot="no-options"
                      @click="$refs.select.open = false"
                    >
                      Chargement...
                    </span>
                    <template #option="{ comment, employee , employee_salary}">
                      <b-row>
                        <b-col md="3">
                          <b-avatar
                            size="80px"
                            rounded
                            :src="employee.profile_image"
                          />
                        </b-col>
                        <b-col md="9">
                          <b-row>
                            <b-col>
                              <span class="text-info">
                                {{ employee.full_name }}
                              </span>
                            </b-col>
                            <b-col>
                              <span class="text-info">
                                {{ employee.age }} ans
                              </span>
                            </b-col>
                          </b-row>
                          <b-row class="">
                            <b-col>
                              <span class="text-info">
                                {{ employee.marital_status }}
                              </span>
                            </b-col>
                            <b-col>
                              <span class="text-info">{{
                                employee.address
                              }}</span>
                            </b-col>
                          </b-row>
                          <b-row class="">
                            <b-col>
                              <span class="text-info">
                                Commentaire: {{ comment }}
                              </span>
                            </b-col>
                            <b-col>
                              <span class="text-info">
                                Salaire net: {{ employee_salary }}
                              </span>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </template>
                    <template #selected-option="employee">
                      {{ employee.full_name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Le Budget du Client"
                rules="required"
              >
                <b-form-group
                  label="Budget du client"
                  label-for="employee_salary"
                >
                  <b-form-input
                    id="employee_salary"
                    v-model="customerBudget"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    trim
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                name="Salaire de l'Employé"
                rules="required"
              >
                <b-form-group
                  label="Salaire de l'employé"
                  label-for="salary"
                >
                  <b-form-input
                    id="employee_amount"
                    v-model="employee_amount"
                    :readonly="getUser.role.slug === 'charge-daffaires'"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    trim
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                name="La Commission"
                rules="required"
              >
                <b-form-group
                  :label="`Commission ylomi  soit ${taux} %`"
                  label-for="commission"
                >
                  <b-form-input
                    id="commission"
                    v-model="ylomi_amount"
                    readonly
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    trim
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  :disabled="isMakingPropositionLoading"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                >
                  <div v-if="isMakingPropositionLoading">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>
                  <span v-else>Modifier</span>
                </b-button>
                <b-button
                  type="reset"
                  variant="outline-secondary"
                  @click="hidePropositionModificationModal()"
                >
                  Annuler
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-modal>

        <!-- Deploy Employee Modal -->
        <b-modal
          id="modal-deploy-employee"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          no-close-on-backdrop
          no-close-on-esc
          title="Déploiement d'employé"
        >
          <validation-observer
            #default="{}"
            ref="deployEmployeeForm"
          >
            <!-- Form -->
            <b-form
              class="p-2"
              autocomplete="off"
              @reset.prevent="hideModalDeployEmployee()"
              @submit.prevent="applyDeployEmployeeContractAction()"
            >
              <b-form-group
                label="Date de déploiement"
                label-for="role"
              >
                <validation-provider
                  #default="{ errors }"
                  name="date de déploiement"
                  rules="required"
                >
                  <flat-pickr
                    v-model="deployEmployeePayload.employee_deployment_date"
                    class="form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  :disabled="isDeployEmployeeLoading"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                >
                  <div v-if="isDeployEmployeeLoading">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>
                  <span v-else>Déployer l'employé</span>
                </b-button>
                <b-button
                  type="reset"
                  variant="outline-secondary"
                >
                  Annuler
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-modal>

        <!-- List Desistement Modal -->
        <b-modal
          id="modal-terminated-employee"
          cancel-variant="outline-secondary"
          hide-footer
          centered
          no-close-on-backdrop
          no-close-on-esc
          title="Désistements"
        >
          <b-table
            ref="refInvoiceListTable"
            :items="listOfTerminatedEmployees"
            responsive
            :fields="terminatedEmployeesColumns"
            primary-key="id"
            :busy="isTerminatedEmployees"
            show-empty
            empty-text="Liste vide"
            class="position-relative"
          >
            <template #table-busy>
              <div class="text-center text-info my-2">
                <b-spinner class="align-middle" /> &nbsp;
                <strong>Chargement...</strong>
              </div>
            </template>

            <!-- Column: Client -->
            <template #cell(profile_image)="data">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    rounded
                    :text="avatarText(data.item.recommandation.employee.full_name)"
                    size="42"
                    :src="data.item.employee.profile_image"
                  />
                </template>
                <span class="font-weight-bold d-block text-nowrap">
                  {{ data.item.recommandation.employee.full_name }}
                </span>
                <small class="text-success font-small-3">
                  {{ data.item.recommandation.employee.phone_number }}</small>
              </b-media>
            </template>

            <!-- Column: Employee contract started date -->
            <template #cell(employee_contract_started_date)="data">
              <span>
                {{
  moment(data.item.recommandation.employee_contract_started_date)
                    .locale("fr")
                    .format("dddd DD MMMM YYYY")
                }}
              </span>
            </template>

            <template #cell(reason)="data">
              <span class="text-info">
                {{
                  data.item.reason != null ? data.item.reason : 'Non renseignée'
                }}
              </span>
            </template>

            <!-- Column: Employee contract startended date -->
            <template #cell(employee_contract_end_date)="data" >
              <span>
                {{
  moment(data.item.recommandation.employee_contract_end_date)
                    .locale("fr")
                    .format("dddd DD MMMM YYYY")
                }}
              </span>
            </template>
          </b-table>
        </b-modal>
      </template>
    </div>
    <b-modal
      id="modal-archivage"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
      title="Raison de l'archivage"
    >
      <validation-observer
        #default="{}"
        ref="archivingOrderForm"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="off"
          @reset.prevent="hideModal"
          @submit.prevent="applyArchiveDirectOrderAction()"
        >
          <validation-provider
            #default="{ errors }"
            name="archivage"
            rules="required"
          >
            <b-form-group
              label="Raison de l'archivage"
              label-for="archivage"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-textarea
                id="archiving-reason"
                v-model="archivingPayload.archiving_reason"
                placeholder="Laissez la raison de l'archivage de cette commande"
                rows="3"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="isArchivingLoading"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <div v-if="isArchivingLoading">
                <span>Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else>Archiver</span>
            </b-button>
            <b-button
              type="reset"
              variant="outline-secondary"
              @click="$bvModal.hide('modal-archivage')"
            >
              Annuler
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BAlert,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BForm,
  BFormTextarea,
  BFormGroup,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BTableLite,
  BDropdown,
  BDropdownItem,
  BCardHeader,
  BCardBody,
  BFormSelect,
  BSpinner,
  BCardText,
  BBreadcrumb,
  BBreadcrumbItem,
  // BPagination,
  BTooltip,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BTab,
    BTableLite,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    BTabs,
    BFormSelect,
    BSpinner,
    BForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    BCardHeader,
    BCardBody,
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BAlert,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,

    BLink,
    BBadge,

    BDropdown,
    BBreadcrumb,
    BBreadcrumbItem,
    BDropdownItem,
    // BPagination,
    BTooltip,
    vSelect,
    flatPickr,
    FeatherIcon,
  },

  data() {
    return {
      proposition: null,
      directOrder: null,
      required,
      propositions: [],
      serviceEmployees: [],
      selectEmployee: [],
      employeeOptions: [],
      managedEmployee: [],
      alphaNum,
      isFeedbackSending: false,
      isMakingPropositionLoading: false,
      isProposedEmployeesLoading: false,
      isEmployeesServicesLoading: false,
      isArchivingLoading: false,
      payload: {
        propositions: [],
        recurring_order_id : this.$route.params.id
      },
      archivingPayload: {
        archiving_reason: '',
      },
      employee_amount: null,
      changingEmployee: {
        percentage: '',
        salary: '',
        employee_id: '',
        employee_amount: '',
      },
      customerBudget: 0,
      currentProposition: {},
      tableColumns1: [
        {
          key: 'employee',
          label: 'Employé',
          sortable: true,
          class: 'font-medium-1',
        },
        {
          key: 'salary',
          label: 'Salaire net',
          sortable: true,
          class: 'font-medium-1',
        },
        {
          key: 'comment',
          label: 'Commentaire',
          sortable: true,
          class: 'font-medium-1',
        },
         {
          key: 'employee_contract_started_date',
          label: 'Date de début du contrat',
          sortable: true,
          class: 'font-medium-1',
        },
         {
          key: 'employee_contract_end_date',
          label: 'Date de fin du contrat',
          sortable: true,
          class: 'font-medium-1',
        },
         {
          key: 'reason',
          label: 'Raison',
          sortable: true,
          class: 'font-medium-1',
        },
        
        {
          key: 'is_rejected',
          label: 'Statut',
          sortable: true,
          class: 'font-medium-1',
        },
        { key: 'actions', class: 'font-medium-1' },
      ],
      avatarText,
      terminateEmployeePayload: {
        date: null,
        reason: '',
      },
      isTerminateEmployeeContratLoading: false,
      deployEmployeePayload: {
        employee_deployment_date: null,
      },
      isDeployEmployeeLoading: false,
      currentDate: null,
      directOrdersSalaryPaymentByOrder: [],
      employee_id: null,
      listOfTerminatedEmployees: [],
      isTerminatedEmployees: false,
      terminatedEmployeesColumns: [
        {
          key: 'profile_image',
          label: 'Identité',
          sortable: true,
          class: 'font-small-3',
        },
        {
          key: 'employee_contract_started_date',
          label: 'Date de début du contrat',
          sortable: true,
          class: 'font-small-3',
        },
        {
          key: 'employee_contract_end_date',
          label: 'Date de fin du contrat',
          sortable: true,
          class: 'font-small-3',
        },
        {
          key: 'reason',
          label: 'Raison',
          sortable: true,
          class: 'font-small-3',
        },
      ],
      isPageLoading: true,
      isError: false,
      errorMessage: '',
    }
  },
  
  computed: {
    ...mapGetters('auth', ['getUser']),
    ...mapGetters('packages', ['getDirectOrder']),
    ...mapGetters('orders', [
      // 'getDirectOrder',
      'getDirectOrdersSalaryPaymentByOrder',
    ]),

   
  },

 
  created() {
    this.getPropositionByIdAction()
    
    this.applyGetDirectOrder(this.$route.params.id)
    
  },
 

  methods: {
    ...mapActions('services', ['getServiceEmployeesAction']),
    ...mapActions('packages', ['getDirectOrderAction','getDirectPackageOrdersAction', 'sendFeedbackAction', 'makePropositionAction', 'getRecommendedEmployeesAction', 'getDirectOrderProposedEmployeesAction', 'getRecommendationEmployeesAction','deleteDirectOrderPropositionAction', 'getPropositionByIdAction']),
    ...mapActions('orders', [
      'makeEmployeeDeploymentAction',
      'terminateEmployeeContractAction',
      'markDirectOrderAsFinishedAction',
      // 'deleteDirectOrderPropositionAction',
      // 'makePropositionAction',
      'deleteDirectOrderAction',
      'resetOrderAction',
      'archiveDirectOrderAction',
      // 'getDirectOrderAction',
      // 'getDirectOrderProposedEmployeesAction',
      'getDirectOrdersSalaryPaymentsByOrderAction',
      'getDirectOrderEmployeesServicesAction',
      // 'getRecommendedEmployeesAction',
      'getDirectOrdersAction',
      'modifyPropositionAction',
      // 'sendFeedbackAction',
      'getArchivedDirectOrdersAction',
      'getTerminatedEmployeesAction',
    ]),
    archiveOrder(order) {
      if (order.status != 0) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Attention',
              icon: 'AlertTriangleIcon',
              text: "Merci de réinitialiser cette commande avant de l'archiver",
              variant: 'warning',
            },
          },
          {
            position: 'top-center',
          },
        )
      } else {
        this.$bvModal.show('modal-archivage')
      }
    },
    ...mapActions('professionals', ['confirmPlacementCostPaidAction']),

    /** ******************************Proposition************************************* */
    applyGetPropositionById(){
      this.getPropositionByIdAction(this.$route.params.id)
      .then(response =>{
        this.proposition = response.data
        console.log(this.proposition)
      })
      .catch(error =>{
        console.log('ERROR',error)
      })
    },
    ApplyGetPropositionDetailsAction(item){
      this.$router.push(`/propositions/recurrent/${item.id}/details`)
    },
    getProposition(item) {
      const recommended_employee = this.employeeOptions.find(el => el.employee.id == item.employee.id)
      this.selectedEmployee = recommended_employee
      this.currentProposition = item
      this.directOrder = this.resolveStatusText(this.directOrder)

      this.$bvModal.show('modal-modify-proposition')
      const { percentage } = this.directOrder.offer_type
      this.changingEmployee.salary = Math.round(
        item.salary / (1 + percentage / 100),
      )
    },
    applyGetDirectOrder(id) {
      // if (this.getDirectOrder !== null) {
      //   this.isPageLoading = false
      //   this.directOrder = this.getDirectOrder
      //   this.customerBudget = this.directOrder.budget
      //   this.applyGetRecommendedEmployeesAction(this.directOrder.id)
      //   this.applyGetDirectOrdersSalaryPaymentByOrderAction(
      //     this.directOrder.id,
      //   )
      // } else {
        this.getDirectOrderAction(id)
          .then(response => {
            
            this.isPageLoading = false
            this.directOrder = response.data
            
            this.directOrder = this.resolveStatusText(this.directOrder)
            console.log('Orderssss',this.directOrder)
            this.customerBudget = this.directOrder.employee_salary
            this.directOrder = this.resolveStatusText(this.directOrder)
            // this.applyGetDirectOrdersSalaryPaymentByOrderAction(
            // this.directOrder.id,
            // )
          })
          .catch(error => {
            console.log(error)
            this.isPageLoading = false
            this.isError = true
            this.errorMessage = Object.values(error.response.data)[0]
          })
      // }
    },

    putRecurringOrdersInStoreAction(directOrder) {
      this.$store.commit(
        'orders/SET_DIRECT_ORDER',
        this.resolveStatusText(directOrder),
      )
      this.$router.push({
        name: 'admin-orders-recurring-details',
        params: { id: directOrder.id },
      })
    },

    applyGetDirectOrderProposedEmployeesAction() {
      this.isProposedEmployeesLoading = true
      const data = {
        recurring_order_id : this.directOrder.id
      }
      this.getDirectOrderProposedEmployeesAction(data)
        .then(response => {
          this.propositions = response.data
          console.log(this.propositions)
          this.isProposedEmployeesLoading = false
        })
        .catch(error => {
          this.isProposedEmployeesLoading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

  //   applyGetRecommendedEmployeesAction(id) {
  //     console.log('id',id)
  // this.getRecommendedEmployeesAction({id:id}).then(response => {
  //       if (response.data.length === 0) {
  //         this.employeeOptions.push({
  //           value: '',
  //           disabled: true,
  //           text: "Aucune recommandation n'est effectué",
  //         })
  //       } else {
  //         response.data.forEach(element => {
  //           if (
  //             element.phone_number === this.directOrder.employee_phone_number
  //           ) {
  //             this.payload.salary = this.directOrder.employee_salary
  //           }
  //           this.employeeOptions.push({
  //             comment: element.comment,
  //             full_name: element.employee.full_name,
  //             id: element.employee.id,
  //             employee_desired_salary: element.employee_desired_salary,
  //             employee: element.employee,
  //           })
  //         })
  //       }
  //     })
  //   },

    applyGetRecommendedEmployeesAction() {
      
      const data = {
        recurring_order_id: this.directOrder.id
       
      }
      console.log(this.directOrder.id)
      this.getRecommendationEmployeesAction(data)
        .then(response => {
          console.log(response.data)
        if (response.data.length === 0) {
          this.employeeOptions.push({
            value: '',
            disabled: true,
            text: "Aucune recommandation n'est effectué",
          })
        } else {
          response.data.forEach(element => {
         
            this.employeeOptions.push({
              comment: element.comment,
              employee_salary: element.employee_salary,
              full_name: element.employee.full_name,
              id: element.employee.id,
              recommandation_id: element.id,
              employee_desired_salary: element.employee_salary,
              employee: element.employee,
            })
          this.proposedEmployeeId = this.resolveStatusPropositionText(this.propositions)

          })
        }
      })
    },

    showEmployeePropositionModal() {
      if (
        this.directOrder.status === -1
        && this.directOrdersSalaryPaymentByOrder.find(el => el.status === 0)
      ) {
        this.$swal({
          title: 'Attention!',
          text: 'Impossible de faire une  proposition. Cette commande résilié à un paiement non payé.·',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        this.$bvModal.show('modal-new-proposition')
      }
    },

    applyMakePropositionAction() {
        this.$refs.newPropositionForm.validate().then(success => {
          if (success) {
            this.isMakingPropositionLoading = true
            this.selectEmployee.forEach(element => {
              this.payload.propositions.push({
                'recommandation_id': element.recommandation_id
              })
            });
            console.log(this.payload)
          
            this.makePropositionAction( this.payload)
              .then(() => {
                this.getDirectOrderAction(this.directOrder.id).then(
                  async response => {
                    this.directOrder = response.data
                    this.directOrder = this.resolveStatusText(this.directOrder)
                    this.isMakingPropositionLoading = false
                    this.$toast(
                      {
                        component: ToastificationContent,
                        props: {
                          title: 'Succès',
                          icon: 'CheckIcon',
                          text: 'Employé proposé avec succès',
                          variant: 'success',
                        },
                      },
                      {
                        position: 'top-center',
                      },
                    )
                    this.hideModal()
                    await this.applyGetDirectOrderProposedEmployeesAction()
                    this.$bvModal.show('modal-proposed-employee')
                  },
                )
                this.getDirectOrdersAction()
              })
              .catch(error => {
                console.log(error)
                this.isMakingPropositionLoading = false
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Error',
                      icon: 'AlertTriangleIcon',
                      text: error.response.data.message,
                      variant: 'danger',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )
              })
          }
        })
      
    },

    applyModifyPropositionAction() {
      if (this.customerBudget <= this.employee_amount) {
        this.isMakingPropositionLoading = false
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              text: "Le salaire de l'employé doit être toujours strictement inférieur au salaire de l'employé du client",
              variant: 'danger',
            },
          },
          {
            position: 'top-center',
          },
        )
      } else if (this.taux < 15) {
        this.isMakingPropositionLoading = false
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              text: 'La commission de YLOMI doit être supérieure  ou égale à 15%',
              variant: 'danger',
            },
          },
          {
            position: 'top-center',
          },
        )
      } else if (this.customerBudget <= 0) {
        this.isMakingPropositionLoading = false
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              text: 'Le salaire de l employé du client doit toujours être strictement supérieur à 0',
              variant: 'danger',
            },
          },
          {
            position: 'top-center',
          },
        )
      } else if (this.employee_amount <= 0) {
        this.isMakingPropositionLoading = false
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              text: "Le salaire de l'employé doit toujours être strictement supérieur à 0",
              variant: 'danger',
            },
          },
          {
            position: 'top-center',
          },
        )
      } else {
        this.$refs.modifyPropositionForm.validate().then(success => {
          if (success) {
            this.isMakingPropositionLoading = true
            if (this.directOrder.search_employee == 1) {
              const comission = this.directOrder.offer_type.name === 'Offre confort'
                ? (parseInt(this.changingEmployee.salary) * 10) / 100
                : parseInt(this.changingEmployee.salary * 15) / 100
              this.changingEmployee.salary = Math.round(
                parseInt(this.changingEmployee.salary) + comission,
              )
            }
            this.changingEmployee.employee_id = this.selectedEmployee.id
            this.changingEmployee.employee_amount = this.employee_amount
            this.changingEmployee.ylomi_amount = this.ylomi_amount
            this.changingEmployee.percentage = this.taux
            this.modifyPropositionAction({
              payload: this.changingEmployee,
              id: this.directOrder.id,
              proposedEmployeeId: this.currentProposition.id,
            })
              .then(() => {
                this.getDirectOrderAction(this.directOrder.id).then(
                  async response => {
                    this.directOrder = response.data
                    this.directOrder = this.resolveStatusText(this.directOrder)
                    this.$store.commit(
                      'orders/SET_DIRECT_ORDER',
                      this.directOrder,
                    )
                    this.isMakingPropositionLoading = false
                    this.$toast(
                      {
                        component: ToastificationContent,
                        props: {
                          title: 'Succès',
                          icon: 'CheckIcon',
                          text: "Proposition d'employée modifié avec succès.",
                          variant: 'success',
                        },
                      },
                      {
                        position: 'top-center',
                      },
                    )
                    this.hidePropositionModificationModal()
                    await this.applyGetDirectOrderProposedEmployeesAction()
                    this.$bvModal.show('modal-proposed-employee')
                  },
                )
                this.getDirectOrdersAction()
              })
              .catch(error => {
                this.isMakingPropositionLoading = false
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Error',
                      icon: 'AlertTriangleIcon',
                      text: error.response.data.message,
                      variant: 'danger',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )
              })
          }
        })
      }
    },

    applyDeleteDirectOrderPropositionAction(propositionId) {
      this.$swal({
        title: 'Suppression',
        text: 'Êtes-vous sûr de vouloir supprimer cette proposition ?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.deleteDirectOrderPropositionAction(propositionId)
          return await this.applyGetDirectOrderProposedEmployeesAction()
        },
      })
        .then(response => {
          console.log(response)
          if (response.isConfirmed) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Offre supprimée',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
          this.$bvModal.show('modal-proposed-employee')
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    /** ******************************Fin Proposition********************************* */

    modifyOrder(directOrder) {
      this.$store.commit('packages/SET_DIRECT_ORDER', directOrder)
      this.$router.push({
        name: 'admin-direct-order-modify',
        params: { id: directOrder.id },
      })
      console.log(directOrder)
    },

    sendPaiementLinkToCustoomer(id, employeeName) {
      window.open(
        `https://wa.me/${this.directOrder.user.phone_number}?text=Nos salutations! \n La date du  paiement de la prestation de votre ${this.directOrder.recurring_service.name} *${employeeName}* étant aujourdhui, merci de cliquer sur lien ci dessous pour effectuer le paiement. *https://ylomi.net/direct/${this.directOrder.id}/payment/${id}*`,
      )
    },

    applyGetDirectOrdersSalaryPaymentByOrderAction(id) {
      this.getDirectOrdersSalaryPaymentsByOrderAction(id)
        .then(response => {
          this.directOrdersSalaryPaymentByOrder = response.data
        })
        .catch(error => {
          this.directOrdersSalaryPaymentByOrder = []
        })
    },

    deployEmployee(item) {
      this.$bvModal.show('modal-deploy-employee')
      this.clickedDeploy = item.id
      console.log('cliked',this.clickedDeploy)
    },

    applyArchiveDirectOrderAction() {
      this.$refs.archivingOrderForm.validate()
        .then(success => {
          if (success) {
            this.$swal({
              title: 'Archivage',
              text: 'Êtes-vous sûr de vouloir archiver cette demande?',
              icon: 'warning',
              showCancelButton: true,
              showLoaderOnConfirm: true,
              cancelButtonText: 'Non',
              allowOutsideClick: false,
              confirmButtonText: 'Oui',
              customClass: {
                confirmButton: 'btn btn-warning',
                cancelButton: 'btn btn-outline-primary ml-1',
              },
              buttonsStyling: false,
              preConfirm: async () => {
                await this.archiveDirectOrderAction({ directOrderId: this.directOrder.id, payload: this.archivingPayload })
                return (
                  (await this.getDirectPackageOrdersAction(this.$route.query.packageId))
                  && this.getArchivedDirectOrdersAction()
                )
              },
            })
              .then(response => {
                if (response.value) {
                  this.directOrder = response.value.data
                  this.directOrder = this.resolveStatusText(this.directOrder)
                  this.$store.commit('orders/SET_DIRECT_ORDER', this.directOrder),
                  this.$router.push({ name: 'admin-orders-recurrent-archived' })
                  this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Succès',
                        icon: 'CheckIcon',
                        text: 'Commande archivée',
                        variant: 'success',
                      },
                    },
                    {
                      position: 'top-center',
                    },
                  )
                }
              })
              .catch(error => {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Erreur',
                      icon: 'AlertTriangleIcon',
                      text: error,
                      variant: 'danger',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )
              })
          }
        })
    },

    applyConfirmedPlacementCostPaidAction(id) {
      this.$swal({
        title: 'Confirmer Frais de Placement',
        text: 'Êtes-vous sûr de vouloir valider les frais de placement ?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-outline ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.confirmPlacementCostPaidAction(this.directOrder.id)
          return await this.getDirectOrdersAction()
        },
      })
        .then(response => {
          if (response.isConfirmed) {
            this.getDirectOrderAction(this.directOrder.id).then(
              async response => {
                this.directOrder = response.data
                this.directOrder = this.resolveStatusText(this.directOrder)
                this.$store.commit('orders/SET_DIRECT_ORDER', this.directOrder)
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Succès',
                      icon: 'CheckIcon',
                      text: 'Frais de placement confirmé avec succès',
                      variant: 'success',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )
                this.getDirectOrdersAction()
              },
            )
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyDeleteDirectOrderAction() {
      this.$swal({
        title: 'Suppression',
        text: 'Êtes-vous sûr de vouloir supprimer cette demande?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.deleteDirectOrderAction(this.directOrder.id)
          return await this.getDirectOrdersAction()
        },
      })
        .then(response => {
          if (response.value) {
            this.$router.push({ name: 'admin-orders-recurring' })
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Demande supprimée',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyResetDirectOrderAction() {
      this.$swal({
        title: 'Réinitialisation',
        text: 'Êtes-vous sûr de vouloir réinitialiser cette commande?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.resetOrderAction(this.directOrder.id)
          // eslint-disable-next-line no-return-await
          return await this.getDirectOrdersAction()
        },
      })
        .then(response => {
          if (response.value) {
            this.$router.go()
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Commande réinitialisée',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyDeployEmployeeContractAction() {
      this.$bvModal.hide('modal-proposed-employee')
      this.isDeployEmployeeLoading = true
      this.$swal
        .fire({
          title: "Déployement d'employé",
          text: 'Êtes-vous sûr de vouloir déployer cet employé ?',
          icon: 'warning',
          showCancelButton: true,
          showLoaderOnConfirm: true,
          cancelButtonText: 'Non',
          allowOutsideClick: false,
          confirmButtonText: 'Oui',
          customClass: {
            confirmButton: 'btn btn-warning',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
          preConfirm: () => this.makeEmployeeDeploymentAction({
            propositionId: this.clickedDeploy,
            payload: this.deployEmployeePayload,
          })
            .then(response => { })
            .catch(error => {
              this.$swal.showValidationMessage(
                `${error.response.data.message}`,
              )
            }),
        })
        .then(response => {
          if (response.isConfirmed) {
            this.hideModalDeployEmployee()
            this.getDirectOrderAction(this.directOrder.id).then(
              async response => {
                this.directOrder = response.data
                this.directOrder = this.resolveStatusText(this.directOrder)
                this.$store.commit('orders/SET_DIRECT_ORDER', this.directOrder)
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Succès',
                      icon: 'CheckIcon',
                      text: 'Employé déployé avec succès',
                      variant: 'success',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )
                this.getDirectOrdersAction()
              },
            )
          } else {
            this.hideModalDeployEmployee()
          }
        })
        .catch(error => {
          this.isDeployEmployeeLoading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error.message,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
    terminateContrat(item){
      this.$bvModal.show('modal-terminate-employee-contrat')
      this.clikedResilier = item.id
      console.log(this.clikedResilier)
    },
    applyTerminateEmployeeContractAction() {
      this.$refs.terminateEmployeeContratForm.validate().then(success => {
        if (success) {
          this.isTerminateEmployeeContratLoading = true
          this.$swal
            .fire({
            title: 'Résilier le contrat employé',
            text: "Êtes-vous sûr de vouloir résilier ce contrat de l'employé actif ?",
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            cancelButtonText: 'Non',
            allowOutsideClick: false,
            confirmButtonText: 'Oui',
            customClass: {
              confirmButton: 'btn btn-warning',
              cancelButton: 'btn btn-outline-primary ml-1',
            },
            buttonsStyling: false,
            preConfirm: async () => await this.terminateEmployeeContractAction({
              propositionId: this.clikedResilier,
              payload: this.terminateEmployeePayload,
            })
            .then(response => { })
            .catch(error => {
              this.$swal.showValidationMessage(
                `${error.response.data.message}`,
              )
            })
          })
            .then(response => {
              
              this.isTerminateEmployeeContratLoading = false
              if (response.value) {
                this.directOrder = response.value.data
                console.log(this.directOrder)
                this.directOrder = this.resolveStatusText(this.directOrder)
                this.$store.commit('orders/SET_DIRECT_ORDER', this.directOrder),
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Succès',
                      icon: 'CheckIcon',
                      text: response.value.message,
                      variant: 'success',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )
                this.$bvModal.hide('modal-terminate-employee-contrat')
                this.getDirectOrdersAction()
              }
            })
            .catch(error => {
              this.isTerminateEmployeeContratLoading = false

              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erreur',
                    icon: 'AlertTriangleIcon',
                    text: error.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            })
        }
      })
    },

    applyMarkDirectOrderAsFinished() {
      this.$swal({
        title: 'Clôturer le dossier',
        text: 'Êtes-vous sûr de vouloir clôturer le dossier ?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => await this.markDirectOrderAsFinishedAction(this.directOrder.id),
      })
        .then(response => {
          if (response.isConfirmed) {
            this.getDirectOrderAction(this.directOrder.id).then(
              async response => {
                this.directOrder = response.data
                this.directOrder = this.resolveStatusText(this.directOrder)
                this.$store.commit('orders/SET_DIRECT_ORDER', this.directOrder)
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Succès',
                      icon: 'CheckIcon',
                      text: 'Contrat clôturé avec succès',
                      variant: 'success',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )
                this.getDirectOrdersAction()
              },
            )
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applySendFeedbackAction() {
      this.isFeedbackSending = true
      this.sendFeedbackAction({
        orderId: this.directOrder.id,
        payload: this.payload,
      })
        .then(async response => {
          this.isFeedbackSending = false
          this.hideModalSendFeedback()
          this.getDirectOrderAction(this.directOrder.id).then(
            async result => {
              this.directOrder = result.data
              this.putRecurringOrdersInStoreAction(this.directOrder)
            },
          )
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Succès',
                icon: 'CheckIcon',
                text: response.message,
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            },
          )
          this.getDirectOrdersAction()
        })
        .catch(error => {
          this.isFeedbackSending = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
  resolvePropositionStatusVariant: (
      status,
      
    ) => {
      if (status === -2) return 'danger'
      if (
        status === 0
       
      ) {
        return 'danger'
      }
      if (status === 0) return 'warning'
      if (status === 1) return 'info'
      if (status === 2) return 'warning'
      if (status === 3 || status === 4) return 'success'
      return 'primary'
    },


     resolveStatusPropositionText(element) {
      if (element.status === -2) {
        element.setStatus = 'Résilié'

        return element
      }
      if (element.status === 1) {
        element.setStatus = 'Employé proposé'
        return element
      }
      if (element.status === 2) {
        element.setStatus = 'Actif'

        return element
      }
      
    },

    resolveOrderStatusVariant: (
      status,
      placement_cost_paid,
      searchEmployee,
      feedback,
    ) => {
      if (status === -1) return 'danger'
      if (
        status === 0
        && placement_cost_paid === false
        && searchEmployee === true
      ) {
        return 'danger'
      }
      if (status === 0) return 'warning'
      if (status === 1) return 'info'
      if (status === 2) return 'warning'
      if (status === 3 || status === 4) return 'success'
      return 'primary'
    },

    resolveStatusText(element) {
      if (
        element.status === 0
        && element.placement_cost_paid === false
        && element.search_employee === true
      ) {
        element.setStatus = 'Non Payé'
        return element
      }
      if (element.status === 0 && element.feedback === null) {
        element.setStatus = 'En attente de feedback'
        return element
      }

      if (
        element.status === 0
        && element.feedback != null
        && element.recommandations_count === 0
      ) {
        element.setStatus = 'En attente de recommandation'
        return element
      }

      if (
        element.status === 0
        && element.feedback != null
        && element.propositions_count === 0
      ) {
        element.setStatus = 'En attente de proposition'

        return element
      }
      if (element.status === -1) {
        element.setStatus = 'Résilié'

        return element
      }
      if (element.status === 1) {
        element.setStatus = 'Employé proposé'
        return element
      }
      if (element.status === 2) {
        element.setStatus = 'Contract émis'

        return element
      }
      if (element.status === 3) {
        element.setStatus = 'Contract approuvé'

        return element
      }
      if (element.status === 4) {
        element.setStatus = 'Actif'
        return element
      }
      if (element.status === 5) {
        element.setStatus = 'Terminée'

        return element
      }
    },

    openWhatsapp(data) {
      window.open(`https://wa.me/${data.user.phone_number}?text=`)
    },
    hideModal() {
      this.$bvModal.hide('modal-new-proposition')
      this.selectedEmployee = ''
      this.payload = {
        salary: '',
        search_employee: '',
        employee_id: '',
      }
    },

    hideModal2() {
      this.$bvModal.hide('modal-new-proposition-2')
      this.payload = {
        salary: '',
        search_employee: '',
        employee_id: '',
      }
    },

    hidePropositionModificationModal() {
      this.$bvModal.hide('modal-modify-proposition')
      this.selectedEmployee = {}
      this.employee_amount = null
      this.changingEmployee = {
        salary: '',
        search_employee: '',
        employee_id: '',
      }
    },

    hideModalTerminateEmployeeContrat() {
      this.$bvModal.hide('modal-terminate-employee-contrat')
      this.terminateEmployeePayload = {
        date: '',
      }
    },

    hideModalSendFeedback() {
      this.$bvModal.hide('modal-send-feedback')
      this.payload = {
        feedback: '',
      }
    },

    hideModalDeployEmployee() {
      this.$bvModal.hide('modal-deploy-employee')
      this.deployEmployeePayload = {
        employee_deployment_date: '',
      }
    },

   
    openEmployeeContract(directOrder) {
      if (directOrder.actif_employee_signature) {
        this.$router.push({
          path: '/preview/pdf',
          query: {
            makeActionOnPage: false, actionType: null, isSigned: true, pdf: proposition.employee_contract_file_name,
          },
        })
      } else {
        this.$router.push({
          path: '/preview/pdf',
          query: {
            makeActionOnPage: true, actionType: 'signature', isSigned: false, pdf: directOrder.employee_contract_file_name, orderId: directOrder.id,
          },
        })
      }
    },

    applyGetTerminatedEmployeesAction() {
      this.isTerminatedEmployees = true
      this.getTerminatedEmployeesAction(this.directOrder.id)
        .then(response => {
          this.isTerminatedEmployees = false
          this.listOfTerminatedEmployees = response.data.data
        })
        .catch(error => {
          this.isTerminatedEmployees = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            >.col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style>
.itemActions :hover {
  background: transparent !important;
  color: white;
}

.dark-layout .v-select:not(.vs--single) .vs__selected{
  color: white!important;
}
</style>
